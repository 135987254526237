import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import BlogPreview from '../components/blog-preview'

const Blog = ( props, { data }) => (
  
  <Layout>
    <Helmet title="Blog | Keep up with the latest news from us | Rudd Joinery">
      <meta name="description" content="Keep up with the latest news and from the Rudd Joinery blog posts" />
    </Helmet>
    <div className="section">
      <div className="wrapper">
        <div className="text-block story">
          <h2 className="heading">Latest posts</h2>
          <p>
            Keep up with the latest news from us
          </p>
        </div>
      </div>
    </div>    
    <div className="wrapper cf blogs">
      {
        props.data.allContentfulBlogPost.edges.map((item, index) => (                
            <BlogPreview key={item.node.id} article={item.node} />
        ))
      }
    </div>
  </Layout>
)

export default Blog

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "DD MMMM YYYY")
          content {
            childMarkdownRemark {
              excerpt(pruneLength:250)
            }
          }
          coverImage {
            fluid(maxWidth:900)  {
              aspectRatio
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
           } 
          }
          #coverImage {
          #  sizes(maxWidth: 960, resizingBehavior: SCALE) {
          #    ...GatsbyContentfulSizes_tracedSVG
          #  }
          #}
        }
      }
    }
  }
`